import type { ChatFastAction } from "@do/shared-types";
import { Apple, Google } from "@mui/icons-material";
import type { ButtonProps, SxProps } from "@mui/material";
import { Button, styled, useTheme } from "@mui/material";
import { size } from "polished";
import { memo, useCallback } from "react";
import { strTemplateToStr } from "../tools";

const HtmlWrapper = styled("span")({
  "& .fastActionContent": {
    display: "flex",
    alignItems: "center",
  },
  "& img": {
    ...size(28),
    marginRight: 10,
  },
  "& .fastActionTitles": {
    display: "flex",
    flexDirection: "column",
  },
  "& .fastActionSubTitle": {
    marginTop: 4,
    fontSize: "0.6rem",
  },
});

export type ActionProps = {
  disabled?: boolean;
  onFastAction: (resp: ChatFastAction) => void;
};

export type FastActionProps = ChatFastAction &
  ActionProps & {
    isPreviewMode: boolean;
  };

// Common to all buttons
const buttonSxProps: SxProps = {
  boxShadow: "2px 2px 8px rgba(105, 120, 140, 0.15)",
  fontSize: "1rem",
  height: "fit-content",
  lineHeight: 1.2,
  padding: "8px 10px",
  textAlign: "left",
  textTransform: "none",
};

// Specific to App store buttons
const storeSxProps: SxProps = {
  background: "#000 !important",
  color: "#fff !important",
  "&:hover, &.Mui-focusVisible": {
    opacity: 0.95,
  },
};

export const FastAction = memo<FastActionProps>(
  ({
    appId,
    appName,
    code,
    disabled,
    fsmDef,
    id,
    isPreviewMode,
    metadata,
    onFastAction,
    packageName,
    payload,
    tel,
    template,
    title,
    url,
  }) => {
    const theme = useTheme();
    const isStore = !!appId;
    const handleClick = useCallback(() => {
      onFastAction({
        code,
        fsmDef,
        id,
        metadata,
        payload,
        tel,
        title,
        url,
      });
    }, [code, fsmDef, id, metadata, onFastAction, payload, tel, title, url]);
    const handleAppleClick = useCallback(() => {
      onFastAction({
        id,
        metadata,
        title,
        url: `https://apps.apple.com/app/${appName}/${appId}`,
      });
    }, [appId, appName, id, metadata, onFastAction, title]);
    const handleAndroidClick = useCallback(() => {
      onFastAction({
        id,
        metadata,
        title,
        url: `https://play.google.com/store/apps/details?id=${packageName}`,
      });
    }, [id, metadata, onFastAction, packageName, title]);
    const metadataStr = JSON.stringify(Object.assign({}, ...(metadata || [])));
    const buttonProps: ButtonProps & { "data-button-metadata": string } = {
      className: "fastAction",
      "data-button-metadata": metadataStr,
      disabled,
      size: "small",
      sx: { borderRadius: theme.spacing(2), ...buttonSxProps, ...storeSxProps },
    };
    return isStore ? (
      <>
        {window.isIos && (
          <Button
            id={`${id}-apple`}
            onClick={handleAppleClick}
            startIcon={<Apple />}
            {...buttonProps}
          >
            App Store
          </Button>
        )}
        {window.isAndroid && (
          <Button
            id={`${id}-google`}
            startIcon={<Google />}
            onClick={handleAndroidClick}
            {...buttonProps}
          >
            Google Play
          </Button>
        )}
      </>
    ) : (
      <Button
        id={id}
        onClick={handleClick}
        {...buttonProps}
        sx={{
          color: theme.palette.common.black,
          border: 1,
          borderColor: theme.palette.primary.main,
          borderRadius: theme.spacing(2),
          backgroundColor: theme.palette.common.white,
          ...buttonSxProps,
          "&:disabled": {
            color: theme.palette.grey[400],
            backgroundColor: theme.palette.common.white,
            ...(isPreviewMode && {
              color: theme.palette.common.black,
              borderColor: theme.palette.primary.main,
              backgroundColor: theme.palette.common.white,
            }),
          },
        }}
      >
        <HtmlWrapper
          className="fastActionContentWrapper"
          dangerouslySetInnerHTML={{
            __html: template && title ? strTemplateToStr(template, { title }) : title || "",
          }}
        />
      </Button>
    );
  }
);
