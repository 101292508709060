import type { TextFieldProps } from "@mui/material";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import type { ChangeEvent, FormEvent } from "react";
import { memo, useCallback, useState } from "react";
import { internalWidth } from "../constants";
import { MarkdownRenderer, type MarkdownRendererProps } from "./MarkdownRenderer";
import { StepContainer } from "./StepContainer";

export enum FieldType {
  markdown = "markdown",
  select = "select",
  textfield = "textfield",
}

type FieldMarkdown = {
  type: FieldType.markdown;
  props: MarkdownRendererProps;
};

type FieldTextField = {
  type: FieldType.select | FieldType.textfield;
  props: TextFieldProps & { options?: { label: string; value: string }[] };
};

export type FieldDef = FieldMarkdown | FieldTextField;

export type ChatFormProps = {
  disabled?: boolean;
  formSchema: {
    fields: Record<string, FieldDef>;
    submit: string;
  };
  onSubmit: (values: object) => void;
};

const escapeValue = (value: string) => {
  const escapedQuotes = value.replace(/"/g, '\\"');
  const escapedLineBreaks = escapedQuotes.replace(/(\r\n|\r|\n)/g, "\\n");
  const trimmed = escapedLineBreaks.trim();

  return trimmed;
};

type FormState = Record<string, { error?: string; value: string }>;

export const ChatForm = memo<ChatFormProps>(({ disabled, formSchema, onSubmit }) => {
  const [formState, setFormState] = useState<FormState>({});
  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onSubmit(
        Object.keys(formState).reduce((acc, key) => {
          const escapedValue = escapeValue(formState[key].value);
          return { ...acc, [key]: escapedValue };
        }, {})
      );
    },
    [formState, onSubmit]
  );
  return (
    <StepContainer maxWidth={internalWidth}>
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" gap={1.5}>
          {Object.entries(formSchema.fields).map(([id, { props, type }]) => {
            switch (type) {
              case FieldType.markdown:
                return <MarkdownRenderer key={`md-${id}`} text={props.text} />;
              case FieldType.textfield:
              case FieldType.select:
                return (
                  <TextField
                    disabled={disabled}
                    fullWidth
                    id={id}
                    key={id}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setFormState((formState) => ({
                        ...formState,
                        [id]: { value: e.target.value },
                      }))
                    }
                    select={type === FieldType.select}
                    size="small"
                    value={formState[id]?.value || ""}
                    {...props}
                  >
                    {type === FieldType.select &&
                      props?.options &&
                      props.options.map(({ label, value }) => (
                        <MenuItem id={id} key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                  </TextField>
                );
              default:
                // eslint-disable-next-line no-console
                console.warn(`Unknown field type ${type}`);
                return null;
            }
          })}
        </Box>
        <Box pt={1.5} textAlign="right">
          <Button
            disabled={disabled}
            type="submit"
            variant="contained"
            sx={{
              textTransform: "capitalize",
            }}
          >
            {formSchema.submit}
          </Button>
        </Box>
      </form>
    </StepContainer>
  );
});
