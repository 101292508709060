import type { ChatbotParams } from "@do/shared-types";
import { SendRounded } from "@mui/icons-material";
import { Box, IconButton, TextField, useTheme } from "@mui/material";
import { position, size } from "polished";
import type { ChangeEvent, FC, FormEvent, RefObject } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { PoweredByDialonce } from "../atoms";
import { chatTextInputHeight, internalWidth } from "../constants";

export type ChatTextInputUiProps = {
  disabled?: boolean;
  showPoweredByDialonce?: ChatbotParams["showPoweredByDialonce"];
  onSubmit: (answer: string) => void;
  placeholder?: string;
  readonlyModeMessage?: string;
  inputRef?: RefObject<HTMLInputElement>;
};

export const ChatTextInputUi: FC<ChatTextInputUiProps> = ({
  disabled,
  showPoweredByDialonce,
  onSubmit,
  placeholder,
  inputRef,
  readonlyModeMessage,
}) => {
  const [text, setText] = useState("");
  const theme = useTheme();
  const formRef = useRef<HTMLFormElement>();

  useEffect(() => {
    if (formRef.current) formRef.current.autocomplete = "off";
  }, []);

  const handleSubmit = useCallback(
    (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      const sanitizedText = text.trim();
      if (sanitizedText) {
        onSubmit(sanitizedText);
        setText("");
      }
    },
    [onSubmit, text]
  );

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setText(e.target.value.slice(0, 511));
  }, []);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  return (
    <Box
      display="flex"
      justifyContent="center"
      {...position("absolute", null, null, 0, null)}
      {...size(chatTextInputHeight, "100%")}
      zIndex={1}
    >
      <Box
        border={0}
        component="form"
        display="flex"
        flexDirection="column"
        maxWidth={internalWidth}
        onSubmit={handleSubmit}
        overflow="hidden"
        ref={formRef}
        width="100%"
        pb={1.2}
        sx={{
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Box alignItems="center" display="flex" flex="1 1 auto" gap={1} width="100%" px={1.5}>
          <TextField
            inputRef={inputRef}
            disabled={disabled || !!readonlyModeMessage}
            inputProps={{ autoComplete: "false", style: { maxHeight: 36 } }}
            maxRows={3}
            multiline
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            sx={{
              flex: "1 1 auto",
              maxHeight: 49,
              p: 0,
              fontSize: "1rem",
              "& .MuiInputBase-root": {
                backgroundColor: theme.palette.background.paper,
              },
              "& fieldset": { border: "none" },
            }}
            value={readonlyModeMessage || text}
          />
          <Box display="flex" alignItems="center">
            <IconButton
              data-test="send"
              disabled={disabled || !text}
              type="submit"
              sx={{
                backgroundColor: "secondary.main",
                color: "secondary.contrastText",
                opacity: disabled ? 0.4 : 1,
                p: 0,
                pb: 0.43,
                pl: 0.48,
                transition: "opacity 0.5s ease-in-out",
                ...size(36),
                "&:hover": {
                  backgroundColor: "secondary.light",
                },
              }}
            >
              <SendRounded sx={{ transform: "rotate(-45deg)" }} />
            </IconButton>
          </Box>
        </Box>
        {showPoweredByDialonce && (
          <Box lineHeight={0} textAlign="center">
            <PoweredByDialonce />
          </Box>
        )}
      </Box>
    </Box>
  );
};
