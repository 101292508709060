import type { ChatbotParams } from "@do/shared-types";
import { availableFonts, FONT_ID_TO_FONT_FAMILY_MAP } from "@do/shared-types";
import type { Theme } from "@mui/material";
import { createTheme, keyframes } from "@mui/material";
import { position, size } from "polished";
import { ensureValidMUIColor } from "../tools";

type T = string | number | { [k: string]: T };
declare module "@mui/material" {
  interface Theme {
    bouncingDots?: ThemeOptions["bouncingDots"];
  }
  interface ThemeOptions {
    bouncingDots?: Record<string, T>;
  }
}

const bouncingDotsAnimation = keyframes`
    0% {
        background-color: lightgray;
    }
    50%,
    100% {
        background-color: gray;
    }
`;

const fallbackPrimary1Color = "#000";
const fallbackPrimary2Color = "#555";

type UseCreateTheme = (
  colorValues: Pick<ChatbotParams, "colorPrimary1" | "colorPrimary2" | "fontId">
) => Theme;

export const useCreateTheme: UseCreateTheme = ({ colorPrimary1, colorPrimary2, fontId }) =>
  createTheme({
    bouncingDots: {
      ...size(7),
      animation: `${bouncingDotsAnimation} 0.5s infinite linear alternate`,
      animationDelay: "0.3s",
      backgroundColor: "lightgray",
      borderRadius: 5,
      color: "lightgray",
      marginLeft: 1,
      marginRight: 1,
      position: "relative",
      "&:before, &:after": {
        ...size("inherit"),
        animation: `${bouncingDotsAnimation} 0.5s infinite alternate`,
        background: "inherit",
        borderRadius: 5,
        color: "inherit",
        content: '""',
      },
      "&:before": {
        ...position("absolute", 0, null, null, -12),
        animationDelay: 0,
      },
      "&:after": {
        ...position("absolute", 0, null, null, 12),
        animationDelay: "0.6s",
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          size: "small",
          sx: { fontSize: "1em" },
        },
      },
      MuiFormControl: {
        defaultProps: {
          margin: "dense",
          sx: { m: 0 },
        },
      },
      MuiFormGroup: {
        defaultProps: {
          sx: { borderRadius: 1 },
        },
      },
      MuiFormHelperText: {
        defaultProps: {
          margin: "dense",
          sx: { bgcolor: "background.paper", borderRadius: 1, px: 0.5 },
        },
      },
      MuiIconButton: {
        defaultProps: { size: "small" },
      },
      MuiInputBase: {
        defaultProps: {
          margin: "dense",
          size: "small",
          sx: { bgcolor: "background.paper", fontSize: "1em" },
        },
      },
      MuiInputLabel: {
        defaultProps: {
          margin: "dense",
          sx: { bgcolor: "background.paper", borderRadius: 1, fontSize: "1em", px: 0.5 },
        },
      },
      MuiListItem: {
        defaultProps: {
          dense: true,
        },
      },
      MuiTextField: {
        defaultProps: {
          margin: "none",
          variant: "outlined",
        },
      },
    },
    palette: {
      primary: {
        main: ensureValidMUIColor(colorPrimary1) ?? fallbackPrimary1Color,
      },
      secondary: {
        main: ensureValidMUIColor(colorPrimary2) ?? fallbackPrimary2Color,
      },
    },
    typography: {
      fontFamily: (fontId && FONT_ID_TO_FONT_FAMILY_MAP[fontId]) ?? availableFonts.Roboto,
    },
  });
