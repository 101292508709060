import type { ChatbotParams } from "@do/shared-types";
import { ArrowDownwardRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { position, size } from "polished";
import type { FC } from "react";
import { memo } from "react";
import { chatTextInputHeight } from "../constants";

export type ScrollToBottomButtonProps = {
  noUserInput: ChatbotParams["noUserInput"];
  scrollToBottom: () => void;
};

export const ScrollToBottomButton: FC<ScrollToBottomButtonProps> = memo(
  ({ noUserInput, scrollToBottom }) => (
    <IconButton
      onClick={() => scrollToBottom()}
      sx={{
        backgroundColor: "secondary.main",
        color: "secondary.contrastText",
        ...position("absolute", null, 16, (noUserInput ? 0 : chatTextInputHeight) + 16, null),
        ...size(36),
        zIndex: 2,
        "&:hover": {
          backgroundColor: "secondary.light",
        },
      }}
    >
      <ArrowDownwardRounded />
    </IconButton>
  )
);
ScrollToBottomButton.displayName = "ScrollToBottomButton";
