import { styled } from "@mui/material";
import { margin } from "polished";
import { memo } from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

const MarkdownWrapper = styled("div")(({ theme }) => ({
  "& iframe, & img, & video": {
    maxWidth: "100%",
  },
  "& p": {
    ...margin("0.3rem", null),
    lineHeight: 1.4,
  },
  "& h1, & h2, & h3": {
    ...margin("0.3rem", null),
    lineHeight: "normal",
  },

  "& table": {
    borderCollapse: "collapse",
    width: "100%",
    marginBottom: 20,
  },
  "& th, & td": {
    border: `1px solid ${theme.palette.text.disabled}`,
    padding: 8,
    textAlign: "left",
  },
  "& hr": {
    ...margin(20, 0),
    border: `1px solid ${theme.palette.text.disabled}`,
  },
  "& p:first-of-type": {
    margin: 0,
  },
  "& blockquote": {
    borderLeft: `2px solid ${theme.palette.text.primary}`,
    margin: 0,
    paddingLeft: 10,
    color: theme.palette.text.primary,
  },
  "& ul, & ol": {
    paddingLeft: "1.1rem",
    ...margin(0, null, "0.3rem"),
  },
  "& a, &a:visited": {
    color: `${theme.palette.primary.main}`,
  },
}));

export type MarkdownRendererProps = {
  text: string;
};

export const MarkdownRenderer = memo<MarkdownRendererProps>(
  ({ text }) => (
    <MarkdownWrapper>
      <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
        {text}
      </Markdown>
    </MarkdownWrapper>
  ),
  (oldProps, newProps) => oldProps.text === newProps.text
);
MarkdownRenderer.displayName = "MarkdownRenderer";
