import type { ChatbotParams } from "@do/shared-types";
import { theme } from "@do/ui-do";
import { Box, Typography } from "@mui/material";
import { padding } from "polished";
import type { FC } from "react";
import { memo } from "react";
import { chatTextInputHeight, internalWidth } from "../constants";
import { MarkdownRenderer } from "./MarkdownRenderer";

export type DisclaimerProps = Pick<
  ChatbotParams,
  "disclaimerBgcolor" | "disclaimerPosition" | "disclaimerText"
>;

export const Disclaimer: FC<DisclaimerProps> = memo(
  ({ disclaimerBgcolor, disclaimerPosition = "bottom", disclaimerText }) => (
    <Box
      alignSelf={disclaimerPosition === "bottom" ? "flex-end" : "flex-start"}
      display="flex"
      justifyContent="center"
      mb={disclaimerPosition === "bottom" ? `${chatTextInputHeight + 16}px` : undefined}
      mt={disclaimerPosition === "bottom" ? "auto" : undefined}
      order={disclaimerPosition === "bottom" ? "100" : undefined}
      width="100%"
      px={2}
    >
      <Typography
        bgcolor={disclaimerBgcolor ?? undefined}
        component="div"
        className="disclaimer"
        maxWidth={internalWidth}
        p={2}
        sx={{
          ...padding("6px", "15px"),
          color: theme.palette.grey[500],
          borderRadius: "15px",
          fontSize: "0.6rem",
          boxShadow: "2px 2px 8px rgba(105, 120, 140, 0.15)",
          lineHeight: 1.2,
        }}
      >
        <MarkdownRenderer text={disclaimerText as string} />
      </Typography>
    </Box>
  )
);
Disclaimer.displayName = "Disclaimer";
