import type { ChatbotParams, Locale } from "@do/shared-types";
import { Flag, flagNames, localeToFlag } from "@do/ui-flags";
import { Cached, CloseRounded } from "@mui/icons-material";
import type { PopoverOrigin, SxProps } from "@mui/material";
import { Box, IconButton, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { ellipsis, position, size } from "polished";
import type { CSSProperties, MouseEvent } from "react";
import { memo, useCallback, useState } from "react";
import { IconAsset } from "../atoms";

export type ChatHeaderProps = Required<Pick<ChatbotParams, "logoDataUri" | "title">> &
  Pick<ChatbotParams, "availableLocales"> & {
    currentLocale?: Locale | null;
    disabled?: boolean;
    enableCloseButton?: boolean;
    enableLanguageSwitcher?: boolean;
    enableRefreshButton?: boolean;
    onChangeLocale?: (selectedLocale: Locale) => void;
    onClose: () => void;
    onRefreshConversation?: () => void;
  };

const anchorOrigin: PopoverOrigin = { vertical: "bottom", horizontal: "right" };
const iconSx: SxProps = {
  backgroundColor: "secondary.main",
  color: "secondary.contrastText",
  ...size(36),
  "&:hover": {
    background: "secondary.light",
  },
};
const listFlagSx: SxProps = { mr: 1 };
const logoStyle: CSSProperties = {
  objectFit: "contain",
  maxWidth: 50,
  maxHeight: 50,
  ...size("100%"),
};
const slotProps = {
  paper: {
    elevation: 0,
    sx: {
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      "&::before": {
        ...size(10),
        ...position("absolute", 0, 15, null, null),
        bgcolor: "background.paper",
        content: '""',
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },
  },
};
const transformOrigin: PopoverOrigin = { vertical: "top", horizontal: "right" };

export const ChatHeader = memo<ChatHeaderProps>(
  ({
    availableLocales,
    currentLocale,
    disabled,
    enableCloseButton,
    enableLanguageSwitcher,
    enableRefreshButton,
    logoDataUri,
    onChangeLocale,
    onClose,
    onRefreshConversation,
    title,
  }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const showFlagsMenu = enableLanguageSwitcher && Object.keys(availableLocales || {}).length > 1;
    const handleClose = useCallback(() => {
      setAnchorEl(null);
    }, []);
    const handleOpen = useCallback((event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    }, []);
    const handleClickMenuIem = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        const newLocale = e.currentTarget.dataset["locale"] as Locale;
        if (onChangeLocale) onChangeLocale(newLocale);
        handleClose();
      },
      [handleClose, onChangeLocale]
    );
    return (
      <Box
        alignContent="center"
        bgcolor="background.paper"
        borderBottom={`1px solid ${theme.palette.divider}`}
        display="flex"
        flexDirection="row"
        gap={1.5}
        p={2}
        width="100%"
      >
        <Box flex="0 0 auto" width={50} height={50}>
          <IconAsset alt="logo" dataURI={logoDataUri} style={logoStyle} />
        </Box>
        <Box alignItems="center" display="flex" flex="1 1 auto" overflow="hidden">
          <Typography fontSize={17} fontWeight="700" sx={ellipsis("100%")}>
            {title}
          </Typography>
        </Box>

        <Box alignItems="center" display="flex" gap={0.5}>
          {availableLocales && showFlagsMenu && currentLocale && (
            <>
              <IconButton disabled={disabled} disableRipple onClick={handleOpen}>
                <Flag
                  immediatelyVisible
                  locale={currentLocale}
                  sx={{
                    alignItems: "center",
                    background: theme.palette.background.paper,
                    border: `2px solid ${
                      disabled ? theme.palette.divider : theme.palette.secondary.main
                    }`,
                    borderRadius: "50%",
                    display: "inline-flex",
                    justifyContent: "center",
                    opacity: disabled ? 0.6 : 1,
                    transition: "300ms ease-in-out",
                    ...size(36),
                    "&::after": {
                      ...position("absolute", null, null, 11, "50%"),
                      ...size(0),
                      content: '""',
                      display: "inline-block",
                      borderTop: `4px solid ${
                        disabled ? theme.palette.divider : theme.palette.secondary.main
                      }`,
                      borderRight: "4px solid transparent",
                      borderLeft: "4px solid transparent",
                      opacity: open ? 0 : 1,
                      transform: "scale(0.9, 0.9) translateX(-50%)",
                      transition: "300ms ease-in-out",
                    },
                    "&>img": {
                      borderRadius: 3,
                      ...size(24),
                    },
                  }}
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={anchorOrigin}
                onClick={handleClose}
                onClose={handleClose}
                open={open}
                slotProps={slotProps}
                transformOrigin={transformOrigin}
              >
                {Object.keys(availableLocales)
                  .filter((locale) => locale.substring(0, 2) !== currentLocale.substring(0, 2))
                  .map((locale) => (
                    <MenuItem
                      component="button"
                      data-locale={locale}
                      dense
                      key={locale}
                      onClick={handleClickMenuIem}
                    >
                      <Flag locale={locale} sx={listFlagSx} />
                      {flagNames[localeToFlag(locale) as keyof typeof flagNames]}
                    </MenuItem>
                  ))}
              </Menu>
            </>
          )}
          {enableRefreshButton && (
            <IconButton disableRipple onClick={onRefreshConversation} sx={iconSx}>
              <Cached />
            </IconButton>
          )}

          {enableCloseButton && (
            <IconButton disableRipple onClick={onClose} sx={iconSx}>
              <CloseRounded />
            </IconButton>
          )}
        </Box>
      </Box>
    );
  }
);
ChatHeader.displayName = "ChatHeader";
