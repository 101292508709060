import { Box, useTheme } from "@mui/material";
import type { FC } from "react";
import { memo } from "react";

export const BouncingDots: FC = memo(
  () => {
    const theme = useTheme();
    return <Box sx={theme.bouncingDots} />;
  },
  () => true
);
