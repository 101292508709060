import { template } from "lodash";

type ExpectedData = object | Record<string, string | Record<string, string>>;

const rawStrTemplateToStr = (str: string, data: ExpectedData) => {
  const compiled = template(str);
  const result = compiled(data);
  if (result.match(/\$\{/)) return rawStrTemplateToStr(result, data);
  return result;
};

export const strTemplateToStr = (str: string, data: ExpectedData): string => {
  try {
    return rawStrTemplateToStr(str, data);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn("strTemplateToStr", str, data, e);
    return `${e}`;
  }
};

export const objTemplateToObj = (obj: object, data: ExpectedData): object => {
  try {
    return JSON.parse(rawStrTemplateToStr(JSON.stringify(obj), data));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn("objTemplateToObj", obj, data, e);
    return { error: `${e}` };
  }
};
