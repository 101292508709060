import type { FsmDefDoBotState } from "@do/shared-types";
import { memo, useEffect } from "react";
import type { ChatStepsProps } from "./types";

type DoBotStepProps = {
  action: FsmDefDoBotState["meta"]["action"];
  onFastAction: ChatStepsProps["onFastAction"];
  payload: string;
};

export const DoBotStep = memo<DoBotStepProps>(
  ({ action, onFastAction, payload }) => {
    useEffect(() => {
      onFastAction({
        metadata: [{ form: payload }],
        payload: `/${action}`,
        title: "",
      });
    }, [action, onFastAction, payload]);

    return null;
  },
  () => true
);
