import type { SxProps } from "@mui/system";
import { size } from "polished";

export const staticRootStyles: SxProps = {
  ...size("100%"),
  background: "transparent",
  boxShadow: "unset",
  margin: 0,
  padding: 0,
};
export const globalStyles = {
  html: staticRootStyles,
  body: staticRootStyles,
  "#__next": staticRootStyles,
};
