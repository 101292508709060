import type { BoxProps } from "@mui/material";
import { Box, useTheme } from "@mui/material";

export const StepContainer = ({ sx = {}, ...props }: BoxProps) => {
  const theme = useTheme();
  return (
    <Box
      fontSize={12}
      p={2}
      position="relative"
      sx={{
        borderRadius: "0px 15px 15px",
        background: theme.palette.background.paper,
        boxShadow: "2px 2px 8px rgba(105, 120, 140, 0.15)",
        ...sx,
      }}
      {...props}
    />
  );
};
