import {
  type BlockText,
  type ChatbotParams,
  ChatCustomBlockTypes,
  type ChatMessage,
} from "@do/shared-types";
import { Box, Grow, IconButton, Typography, useTheme } from "@mui/material";
import { clsx } from "clsx";
import { padding } from "polished";
import {
  type FC,
  memo,
  type PropsWithChildren,
  type ReactElement,
  useCallback,
  useEffect,
} from "react";
import { shallowEqualObjects } from "shallow-equal";
import type { ActionProps } from "../atoms";
import { BouncingDots, FastAction, MarkdownRenderer, PencilIcon } from "../atoms";
import { ChatSteps } from "./ChatSteps";

export type EditBotResponseProps = {
  isTestingMode?: boolean;
  onEditBotResponse?: (messageId: string, openDialog: boolean) => void;
};
export type ChatMessageUiProps = ChatMessage &
  ActionProps &
  EditBotResponseProps & {
    chatbotParams: ChatbotParams;
    isPreviewMode?: boolean;
  };

export const ChatMessageUi: FC<ChatMessageUiProps> = memo(
  ({
    blocks,
    chatbotParams,
    disabled,
    onEditBotResponse = () => {},
    id,
    user,
    metadata,
    onFastAction,
    isPreviewMode = false,
    isTestingMode,
  }) => {
    const metadataStr = JSON.stringify(Object.assign({}, ...(metadata || [])));
    const theme = useTheme();

    const isTestingModeGenerativeResponse =
      isTestingMode &&
      typeof metadata === "object" &&
      (metadata?.[0] as Record<string, unknown>)?.["generative_response"];

    const removeHighlight = useCallback(() => {
      const elemsHighlighted = Array.from(document.getElementsByClassName("highlight"));
      elemsHighlighted.forEach((elem) => {
        elem.classList.remove("highlight");
      });
    }, []);

    const highLightBlock = useCallback(
      (blockId: string, e: MouseEvent) => {
        e.stopPropagation();
        removeHighlight();
        const elemToHighLight = document.getElementById(blockId);
        if (elemToHighLight) {
          elemToHighLight.classList.add("highlight");
        }
        onEditBotResponse(id || "", false);
      },
      [id, onEditBotResponse, removeHighlight]
    );

    const onClickOutside = useCallback(
      (e: MouseEvent) => {
        e.stopPropagation();
        // disable highlight if click happens outside a highlighted zone
        const targetClassName = (e?.target as Element)?.className;
        if (!targetClassName?.includes?.("highlight") ?? true) removeHighlight();
        onEditBotResponse("", false);
      },
      [onEditBotResponse, removeHighlight]
    );

    useEffect(() => {
      if (!isTestingMode) return;
      const style = document.createElement("style");
      style.innerHTML = `
        .highlight {
            box-shadow: inset 0px 0px 0px 3px ${theme.palette.secondary.main};
        }`;
      document.head.appendChild(style);
      document.addEventListener("click", onClickOutside);
      return () => {
        document.removeEventListener("click", onClickOutside);
      };
    }, [isTestingMode, onClickOutside, theme.palette.secondary.main, user]);

    return ((blocks?.map((block, idx) => {
      return block.type !== ChatCustomBlockTypes.liveChat ? (
        <Grow in key={`${block.id}-${idx}`}>
          <Box
            data-metadata={metadataStr}
            display="flex"
            flexDirection="column"
            id={id}
            px={2}
            width="100%"
            {...(user ? { pt: 2, pb: 1 } : { pt: 1 })}
          >
            {block.type === ChatCustomBlockTypes.text && (
              <Box
                borderRadius={15}
                display="flex"
                flexDirection="row"
                justifyContent={user ? "flex-end" : "flex-start"}
              >
                <Typography
                  className={clsx({ chatMessageRobot: !user, chatMessageHuman: user })}
                  component="div"
                  id={`block-${id}-${idx}`}
                  onClick={(e) =>
                    isTestingModeGenerativeResponse && !user
                      ? highLightBlock(`block-${id}-${idx}`, e as unknown as MouseEvent)
                      : null
                  }
                  maxWidth="calc(100% - 50px)"
                  textOverflow="initial"
                  variant="body1"
                  sx={{
                    ...padding("6px", "15px"),
                    color: user ? theme.palette.primary.contrastText : theme.palette.text.primary,
                    bgcolor: user ? theme.palette.primary.main : theme.palette.background.paper,
                    borderRadius: user ? "15px 15px 0px" : "0px 15px 15px",
                    boxShadow: "2px 2px 8px rgba(105, 120, 140, 0.15)",
                    lineHeight: 1.4,
                    wordWrap: "break-word",
                  }}
                >
                  {user ? (
                    <span>{(block as BlockText).text}</span>
                  ) : (
                    <Box>
                      <MarkdownRenderer text={block.text} />
                      {isTestingModeGenerativeResponse ? (
                        <Box display="flex" justifyContent="flex-end" mt={2}>
                          <IconButton
                            onClick={() => id && onEditBotResponse(id, true)}
                            disabled={disabled}
                            sx={{
                              padding: 0,
                              opacity: disabled ? 0.4 : 1,
                              transition: "opacity 0.5s ease-in-out",
                            }}
                          >
                            <PencilIcon />
                          </IconButton>
                        </Box>
                      ) : null}
                    </Box>
                  )}
                </Typography>
              </Box>
            )}
            {block.type === ChatCustomBlockTypes.bouncingDots && <BouncingDots />}
            {block.type === ChatCustomBlockTypes.buttons &&
              block.buttons &&
              block.buttons.length > 0 && (
                <Box display="flex" flexWrap="wrap" gap={1} maxWidth="100%">
                  {block.buttons.map((button, idx) => (
                    <FastAction
                      key={`${block.id}-fast-action-${idx}`}
                      disabled={disabled}
                      isPreviewMode={isPreviewMode}
                      onFastAction={onFastAction}
                      id={`${block.id}-button-${idx}`}
                      {...button}
                    />
                  ))}
                </Box>
              )}
            {block.type === ChatCustomBlockTypes.steps && (
              <ChatSteps
                chatbotParams={chatbotParams}
                disabled={disabled}
                onFastAction={onFastAction}
                id={id}
                fsmDef={block.fsmDef}
              />
            )}
          </Box>
        </Grow>
      ) : null;
    }) as unknown as ReactElement) || null) as ReturnType<FC>;
  },
  (
    prevProps: Readonly<PropsWithChildren<PropsWithChildren<ChatMessageUiProps>>>,
    nextProps: Readonly<PropsWithChildren<PropsWithChildren<ChatMessageUiProps>>>
  ) => {
    return shallowEqualObjects(prevProps, nextProps);
  }
);
