import type { FsmDefFormState } from "@do/shared-types";
import { type Dispatch, type SetStateAction, memo, useCallback } from "react";
import type { AnyEventObject } from "xstate";
import { ChatForm, type ChatFormProps } from "../../atoms";
import type { StepValues } from "./types";

export type FormStepProps = Omit<ChatFormProps, "onSubmit"> & {
  onSetStepValue: Dispatch<SetStateAction<StepValues>>;
  send: (event: AnyEventObject) => void;
  stateDef: FsmDefFormState;
  stateValue: string;
};

export const FormStep = memo<FormStepProps>(
  ({ disabled, formSchema, onSetStepValue, send, stateDef, stateValue }) => {
    const handleSubmit = useCallback(
      (formValues: object) => {
        onSetStepValue((stepValues: StepValues) => ({ ...stepValues, [stateValue]: formValues }));
        setTimeout(() => send({ type: Object.keys((stateDef as FsmDefFormState).on)[0] }), 0);
      },
      [onSetStepValue, send, stateValue, stateDef]
    );
    return <ChatForm disabled={disabled} formSchema={formSchema} onSubmit={handleSubmit} />;
  }
);
