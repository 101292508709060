import type { FsmDefLiveChatState } from "@do/shared-types";
import { memo, useEffect } from "react";
import type { ChatStepsProps } from "./types";

type LiveChatStepProps = {
  liveChatConfigId: FsmDefLiveChatState["meta"]["liveChatConfigId"];
  onFastAction: ChatStepsProps["onFastAction"];
  payload: Record<string, string>;
};

export const LiveChatStep = memo<LiveChatStepProps>(
  ({ liveChatConfigId, onFastAction, payload }) => {
    useEffect(() => {
      onFastAction({
        liveChat: { configId: liveChatConfigId, payload },
        title: "liveChat",
      });
    }, [liveChatConfigId, onFastAction, payload]);
    return null;
  },
  () => true
);
