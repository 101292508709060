import { useTheme } from "@mui/material";
import { size } from "polished";
import type { FC, SVGProps } from "react";
import { memo } from "react";

export const PencilIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => {
  const theme = useTheme();

  return (
    <svg viewBox="-3 -3 30 30" {...size(35)} {...props}>
      <circle cx="12" cy="12" r="15" fill={theme.palette.secondary.main} />
      <path
        fill={theme.palette.secondary.contrastText}
        d="m18.4 3.3 2.3 2.3c.4.4.4 1 0 1.4L19 9l-4-4 2-1.7c.1-.2.4-.3.7-.3.2 0 .5 0 .7.3ZM3 17.3V21h3.7l11.1-11-3.7-3.8L3 17.2ZM6 19H5v-1l9-9 1 1-9 9Z"
      />
    </svg>
  );
});

PencilIcon.displayName = "PencilIcon";
