import type { BlockSteps, ChatbotParams, ChatMessage } from "@do/shared-types";
import {
  chatbotParamsDefaultValues,
  ChatCustomBlockTypes,
  FsmDefMetaTypes,
} from "@do/shared-types";

export const fsmDef: BlockSteps["fsmDef"] = {
  initial: "form",
  states: {
    form: {
      meta: {
        type: FsmDefMetaTypes.form,
        formSchema: {
          fields: {
            name: {
              type: "textfield",
              props: {
                label: "Nom",
                required: true,
              },
            },
            phone: {
              type: "textfield",
              props: {
                label: "Mobile",
                required: true,
                type: "tel",
              },
            },
          },
          submit: "Soumettre",
        },
      },
      on: {
        formOnSubmit: { target: "hello" },
      },
    },
    hello: {
      meta: {
        type: FsmDefMetaTypes.markdown,
        // eslint-disable-next-line no-template-curly-in-string
        markdown: "# Bonjour\n\n${form.name} au ${form.phone}",
      },
    },
  },
};

export const messages: ChatMessage[] = [
  {
    id: "1",
    blocks: [{ type: ChatCustomBlockTypes.text, text: "Bonjour et bienvenue" }],
  },
  { id: "2", blocks: [{ type: ChatCustomBlockTypes.text, text: "User" }], user: true },
  {
    id: "3",
    blocks: [
      { type: ChatCustomBlockTypes.text, text: "Message sur 2 lignes - ligne 1" },
      { type: ChatCustomBlockTypes.text, text: "Message sur 2 lignes - ligne 2" },
    ],
  },
  { id: "4", blocks: [{ type: ChatCustomBlockTypes.text, text: "User" }], user: true },
  {
    id: "5",
    blocks: [
      {
        type: ChatCustomBlockTypes.text,
        text: "Exemple de liens",
      },
      {
        type: ChatCustomBlockTypes.buttons,
        buttons: [
          {
            title: "Visualisation des liens",
            url: "🔗 https://www.vattenfall.fr/le-mag-energie/comprendre-ma-facture-electricite-vattenfall?do_source=dialonce",
          },
        ],
      },
      {
        type: ChatCustomBlockTypes.steps,
        fsmDef,
      },
    ],
  },
  { id: "6", blocks: [{ type: ChatCustomBlockTypes.text, text: "User" }], user: true },
  {
    id: "7",
    blocks: [
      {
        type: ChatCustomBlockTypes.text,
        text: "Multiple actions",
      },
      {
        type: ChatCustomBlockTypes.buttons,
        buttons: [
          { payload: "1", title: "1️⃣ Bouton CTA" },
          { payload: "2", title: "2️⃣ Bouton CTA 2" },
        ],
      },
    ],
  },
  { id: "8", blocks: [{ type: ChatCustomBlockTypes.text, text: "User" }], user: true },
  {
    id: "9",
    blocks: [
      {
        type: ChatCustomBlockTypes.text,
        text: "Exemple de script",
      },
      {
        type: ChatCustomBlockTypes.buttons,
        buttons: [
          {
            title: "🧑‍💻 Code",
            code: `alert('Hello world')`,
          },
        ],
      },
    ],
  },
  {
    id: "10",
    blocks: [{ type: ChatCustomBlockTypes.text, text: "Another user message" }],
    user: true,
  },
  {
    id: "11",
    blocks: [
      {
        type: ChatCustomBlockTypes.bouncingDots,
      },
    ],
  },
];

export const messagesWithDisabledInput: ChatMessage[] = [
  {
    blocks: [{ type: ChatCustomBlockTypes.text, text: "Bonjour et bienvenue" }],
    events: {
      disabledInput: true,
    },
    id: "1",
    user: false,
  },
];

export const messagePhone: ChatMessage = {
  id: "1",
  blocks: [
    {
      type: ChatCustomBlockTypes.text,
      text: "Afin de joindre un de nos conseillers, nous vous invitons \u00e0 nous contacter au num\u00e9ro suivant: \ud83d\udcde  ",
    },
    {
      type: ChatCustomBlockTypes.buttons,
      buttons: [{ tel: "+33806800308", title: "0806 800 308" }],
    },
  ],
};

export const messageUrl: ChatMessage = {
  id: "1",
  blocks: [
    {
      type: ChatCustomBlockTypes.text,
      text: "Pour vous aider \u00e0 comprendre votre facture, merci de cliquer sur le lien ci-dessous pour \u00eatre redirig\u00e9 vers votre r\u00e9ponse: ",
    },
    {
      type: ChatCustomBlockTypes.buttons,
      buttons: [
        {
          url: "https://www.vattenfall.fr/le-mag-energie/comprendre-ma-facture-electricite-vattenfall?do_source=dialonce",
          title: "cliquez ici",
        },
      ],
    },
  ],
};

export const messageCode: ChatMessage = {
  id: "1",
  blocks: [
    {
      type: ChatCustomBlockTypes.text,
      text: "merci de cliquer sur le lien ci-dessous pour \u00eatre redirig\u00e9 vers votre r\u00e9ponse: ",
    },
    {
      type: ChatCustomBlockTypes.buttons,
      buttons: [
        {
          code: "alert('TEST')",
          title: "cliquez ici",
        },
      ],
    },
  ],
};

/* eslint-disable no-useless-escape */
export const markdownContent = `
# Complicated Markdown Example

## Videos

You can embed a video using the HTML video tag:

<video
  controls
  src="https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4"
  poster="https://peach.blender.org/wp-content/uploads/title_anouncement.jpg?x11217">
  Sorry, your browser doesn't support embedded videos, but don't worry, you can
  <a href="https://archive.org/details/BigBuckBunny_124">download it</a>
  and watch it with your favorite video player!
</video>

## Text and Headers

Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Varius vel pharetra vel turpis nunc eget lorem dolor. Semper eget duis at tellus at urna condimentum. Vitae congue mauris rhoncus aenean vel. Sodales ut eu sem integer vitae justo. Nisl rhoncus mattis rhoncus urna neque viverra justo nec. Sollicitudin nibh sit amet commodo nulla. Neque ornare aenean euismod elementum nisi quis eleifend quam. Interdum velit euismod in pellentesque massa placerat duis ultricies lacus. Et leo duis ut diam quam nulla porttitor. Pretium quam vulputate dignissim suspendisse.

### Subsection 1

Here's some more text under subsection 1.

### Subsection 2

And more text under subsection 2.

## Lists

- Item 1
- Item 2
  - Subitem 2.1
  - Subitem 2.2

1. Numbered Item 1
2. Numbered Item 2
   1. Subitem 2.1
   2. Subitem 2.2

## Links

[Visit OpenAI](https://www.openai.com)

## Images

![Markdown Logo](https://markdown-here.com/img/icon256.png)

## Code

Inline \`code\` and code blocks:

\`\`\`python
def hello_world():
    print("Hello, World!")
\`\`\`

## Quotes

> This is a block quote. You can use it for highlighting important information.

## Tables

| Header 1 | Header 2 |
|----------|----------|
| Row 1, Col 1 | Row 1, Col 2 |
| Row 2, Col 1 | Row 2, Col 2 |

## Horizontal Line

---

## Audio

You can embed audio as well:

<audio controls>
  <source src="audio.mp3" type="audio/mp3">
  Your browser does not support the audio tag.
</audio>

## PDFs

You can link to PDFs:

[Download PDF](path/to/document.pdf)

## Interactive Widgets

You can embed interactive widgets like Jupyter Notebooks:

\`\`\`python
# This is a code cell
print("Hello, Jupyter!");
\`\`\`

## Mathematical Equations

You can write mathematical equations using LaTeX:

\[
E=mc^2
\]

## Emoji

:rocket: Markdown supports emoji!

## Conclusion

This is just a complex example to showcase various Markdown features. Customize it based on your needs!
`;

export const messageMarkdown: ChatMessage = {
  id: "1",
  blocks: [
    {
      type: ChatCustomBlockTypes.text,
      text: markdownContent,
    },
  ],
};

export const messageAppStores: ChatMessage = {
  id: "1",
  blocks: [
    {
      type: ChatCustomBlockTypes.text,
      text: "Liens et app stores",
    },
    {
      type: ChatCustomBlockTypes.buttons,
      buttons: [
        {
          title: "Rechercher",
          url: "https://www.google.fr",
        },
        {
          appId: "id553834731",
          appName: "candy-crush-saga",
          packageName: "com.king.candycrushsaga",
          title: "App stores",
        },
      ],
    },
  ],
};

export const chatbotParamsWithLangs: ChatbotParams = {
  ...chatbotParamsDefaultValues,
  disclaimerActivate: true,
  disclaimerBgcolor: "#efefef",
  disclaimerPosition: "bottom",
  availableLocales: {
    fr: {
      disclaimerText:
        "**Avant de continuer**, veuillez prendre connaissance de nos [conditions générales](https://google.fr).",
      isDefault: false,
      placeholder: "Comment puis-je vous aider ?",
      title: "Chatbot français",
    },
    en: {
      disclaimerText:
        "**Before continuing**, please read our [general conditions](https://google.fr).",
      isDefault: true,
      placeholder: "How can I assist you?",
      title: "Chatbot anglais",
    },
    de: {
      disclaimerText:
        "**Bevor Sie fortfahren**, lesen Sie bitte unsere [Allgemeinen Geschäftsbedingungen](https://google.de).",
      isDefault: false,
      placeholder: "Wie kann ich Ihnen helfen?",
      title: "Deutscher Chatbot",
    },
    es: {
      disclaimerText:
        "**Antes de continuar**, por favor lea nuestros [términos y condiciones](https://google.es).",
      isDefault: false,
      placeholder: "¿Cómo puedo ayudarte?",
      title: "Chatbot español",
    },
  },
};
