import type { ChatbotParams, ChatMessage } from "@do/shared-types";
import {
  availableFonts,
  chatbotParamsDefaultValues,
  DEFAULT_CDN_URL,
  FONT_ID_TO_FONT_CDN_PATH_MAP,
} from "@do/shared-types";
import type { SxProps } from "@mui/material";
import { Box, ThemeProvider } from "@mui/material";
import { position, size } from "polished";
import type { RefObject } from "react";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Disclaimer, PoweredByDialonce } from "../atoms";
import { ScrollToBottomButton } from "../atoms/ScrollToBottomButton";
import { chatTextInputHeight, spacingBetweenTextInputAndLastMessage } from "../constants";
import { useCreateTheme } from "../hooks/createTheme";
import type {
  ChatHeaderProps,
  ChatMessageUiProps,
  ChatTextInputUiProps,
  EditBotResponseProps,
} from "../molecules";
import { ChatHeader, ChatMessageUi, ChatTextInputUi } from "../molecules";
/* eslint-disable react-hooks/exhaustive-deps*/

export type ChatContainerUiProps = Pick<
  ChatHeaderProps,
  "currentLocale" | "onChangeLocale" | "onRefreshConversation"
> & {
  autoScroll?: boolean;
  chatbotParams: ChatbotParams;
  disabled?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  isOutOfCredits?: boolean;
  isPreviewMode?: boolean;
  isTestingMode?: boolean;
  messages: ChatMessage[];
  noHeader?: boolean;
  onClose?: () => void;
  onEditBotResponse?: EditBotResponseProps["onEditBotResponse"];
  onFastAction: ChatMessageUiProps["onFastAction"];
  onSubmit: ChatTextInputUiProps["onSubmit"];
  readonlyModeMessage?: string;
};

const chatMessageSx = { zIndex: 1 };

const containerSx = size("100%");

const contentSx: SxProps = {
  overflowY: "auto",
  WebkitOverflowScrolling: "touch",
};

const disallowContainerSx = {
  opacity: "80%",
};

const poweredBySx: SxProps = {
  ...position("absolute", null, null, 0, null),
  textAlign: "center",
  width: "100%",
};

export const ChatContainerUi = memo<ChatContainerUiProps>(
  ({
    autoScroll = true,
    chatbotParams,
    currentLocale,
    disabled,
    inputRef,
    isOutOfCredits = false,
    isPreviewMode = false,
    isTestingMode = false,
    messages,
    noHeader,
    onChangeLocale,
    onClose,
    onEditBotResponse,
    onFastAction,
    onRefreshConversation,
    onSubmit,
    readonlyModeMessage,
  }) => {
    const mergedParams = { ...chatbotParamsDefaultValues, ...chatbotParams };
    const {
      availableLocales,
      colorBackgroundMessages,
      colorPrimary1,
      colorPrimary2,
      disclaimerActivate,
      disclaimerBgcolor,
      disclaimerPosition,
      disclaimerText,
      showPoweredByDialonce,
      fontId = availableFonts.Roboto,
      logoDataUri,
      noUserInput,
      placeholder,
      title,
    } = mergedParams;

    const enableCloseButton = isTestingMode ? false : mergedParams.enableCloseButton ?? true;
    const enableLanguageSwitcher = !isTestingMode;
    const enableRefreshButton = isTestingMode ? true : mergedParams.enableRefreshButton ?? false;

    const theme = useCreateTheme({ colorPrimary1, colorPrimary2, fontId });
    const disabledInput = messages[messages.length - 1]?.events?.disabledInput;
    const msgRef = useRef<HTMLDivElement>(null);
    const lastUserMessageIndex = useRef(0);

    const [displayDisclaimer, setDisplayDisclaimer] = useState(true);
    const [isAtBottom, setIsAtBottom] = useState(true);

    const handleClose = useCallback(() => {
      onClose ? onClose() : window.parent.postMessage({ action: "dialonce-close" }, "*");
    }, [onClose]);

    const endMessages = useRef<null | HTMLDivElement>(null);

    const scrollToBottom = useCallback(() => {
      if (!msgRef.current) return;
      setTimeout(() => {
        msgRef.current?.scrollTo({
          top: msgRef.current.scrollHeight,
        });
      }, 100);
    }, []);

    useEffect(() => {
      if (autoScroll) {
        window.addEventListener("resize", scrollToBottom);
        return () => {
          window.removeEventListener("resize", scrollToBottom);
        };
      }

      return () => {};
    }, [autoScroll, scrollToBottom]);

    useEffect(() => {
      const observer = () => {
        const maxHeightToleranceFromBottomBeforeAutoScroll = 70;

        const msgRefIsAtBottom =
          Math.abs(
            (msgRef.current?.scrollHeight || 0) -
              (msgRef.current?.clientHeight || 0) -
              (msgRef.current?.scrollTop || 0)
          ) < maxHeightToleranceFromBottomBeforeAutoScroll;

        setIsAtBottom(msgRefIsAtBottom);
      };

      msgRef.current?.addEventListener("scroll", observer);

      return () => {
        msgRef.current?.removeEventListener("scroll", observer);
      };
    }, [messages]);

    useEffect(() => {
      if (displayDisclaimer && messages?.find((message) => message.user === true && !isPreviewMode))
        setDisplayDisclaimer(false);
      const messagesReversed = [...messages].reverse();
      lastUserMessageIndex.current = messages[messages.length - 1]?.user
        ? messages.length
        : messages.length - messagesReversed.findIndex((e) => e.user) - 1;

      if (autoScroll) scrollToBottom();
    }, [messages, autoScroll, scrollToBottom]);

    const selectLocale =
      (availableLocales && currentLocale && availableLocales[currentLocale]) || null;

    const getDisclaimerText = useMemo(() => {
      return selectLocale?.disclaimerText || disclaimerText;
    }, [disclaimerText, selectLocale]);

    return (
      <ThemeProvider theme={theme}>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          maxWidth="100%"
          overflow="hidden"
          position="relative"
          sx={{ ...containerSx, ...(isOutOfCredits && disallowContainerSx) }}
        >
          {fontId && (
            <link
              href={`${DEFAULT_CDN_URL}${FONT_ID_TO_FONT_CDN_PATH_MAP[fontId]}`}
              rel="stylesheet"
            />
          )}
          {/* Header */}
          {noHeader || (
            <ChatHeader
              availableLocales={availableLocales}
              currentLocale={currentLocale}
              disabled={disabled}
              enableCloseButton={enableCloseButton}
              enableLanguageSwitcher={enableLanguageSwitcher}
              enableRefreshButton={enableRefreshButton}
              logoDataUri={logoDataUri}
              onChangeLocale={onChangeLocale}
              onClose={handleClose}
              onRefreshConversation={onRefreshConversation}
              title={selectLocale?.title || title}
            />
          )}
          {/* Content */}
          <Box
            bgcolor={colorBackgroundMessages}
            className="messages"
            data-test="messages"
            display="flex"
            flex="1 1 auto"
            flexDirection="column"
            paddingTop={2}
            ref={msgRef}
            sx={contentSx}
          >
            {displayDisclaimer && disclaimerActivate && getDisclaimerText && (
              <Disclaimer
                disclaimerBgcolor={disclaimerBgcolor}
                disclaimerPosition={disclaimerPosition}
                disclaimerText={getDisclaimerText}
              />
            )}

            {!isAtBottom && (
              <ScrollToBottomButton noUserInput={noUserInput} scrollToBottom={scrollToBottom} />
            )}

            {messages.map((message, index) => (
              <Box key={`${message.id}-scroll`} sx={chatMessageSx}>
                <ChatMessageUi
                  chatbotParams={chatbotParams}
                  disabled={disabled}
                  isPreviewMode={isPreviewMode}
                  isTestingMode={isTestingMode}
                  key={`message-${message.id}-${index}`}
                  onEditBotResponse={onEditBotResponse}
                  onFastAction={onFastAction}
                  {...message}
                  id={`${message.id}`}
                />
              </Box>
            ))}
            {/* Scroll anchor */}
            <Box
              key={`${messages.length}`}
              mb={noUserInput ? 0 : `${chatTextInputHeight}px`}
              mt={`${spacingBetweenTextInputAndLastMessage}px`}
              ref={endMessages}
            />
          </Box>
          {/* Footer */}
          {noUserInput ? (
            showPoweredByDialonce ? (
              <Box sx={poweredBySx}>
                <PoweredByDialonce />
              </Box>
            ) : null
          ) : (
            <ChatTextInputUi
              disabled={disabled || disabledInput}
              showPoweredByDialonce={showPoweredByDialonce}
              onSubmit={onSubmit}
              placeholder={selectLocale?.placeholder || placeholder}
              readonlyModeMessage={readonlyModeMessage}
              inputRef={inputRef}
            />
          )}
        </Box>
      </ThemeProvider>
    );
  }
);
ChatContainerUi.displayName = "ChatContainerUi";
