import { FsmDefMetaTypes } from "@do/shared-types";
import { useMachine } from "@xstate/react";
import { memo, useMemo, useState } from "react";
import { createMachine } from "xstate";
import { objTemplateToObj, strTemplateToStr } from "../../tools";
import { AxiosStep, type AxiosStepProps } from "./AxiosStep";
import { DoBotStep } from "./DoBotStep";
import { FormStep, type FormStepProps } from "./FormStep";
import { LiveChatStep } from "./LiveChatStep";
import { MarkdownStep } from "./MarkdownStep";
import type { ChatStepsProps, StepValues } from "./types";

export const ChatSteps = memo<ChatStepsProps>(
  ({ chatbotParams, disabled, fsmDef, id, onFastAction }) => {
    const promiseMachine = useMemo(() => createMachine(fsmDef), [fsmDef]);
    const [state, send] = useMachine(promiseMachine);
    // Memory of the FSM
    const [stepValues, setStepValues] = useState<StepValues>({});
    const stateValue = state.value as string;
    const stateDef = fsmDef.states[stateValue];
    const context = { chatbotParams, ...stepValues };
    return (
      <div className="fsmDef" id={id}>
        {stateDef.meta.type === FsmDefMetaTypes.axios && (
          <AxiosStep
            onSetStepValue={setStepValues}
            options={objTemplateToObj(stateDef.meta.options, context) as AxiosStepProps["options"]}
            send={send}
            stateDef={stateDef}
            stateValue={stateValue}
          />
        )}
        {stateDef.meta.type === FsmDefMetaTypes.doBot && (
          <DoBotStep
            action={stateDef.meta.action}
            onFastAction={onFastAction}
            payload={strTemplateToStr(stateDef.meta.payloadTransformer, context)}
          />
        )}
        {stateDef.meta.type === FsmDefMetaTypes.form && (
          <FormStep
            disabled={disabled}
            formSchema={
              objTemplateToObj(stateDef.meta.formSchema, context) as FormStepProps["formSchema"]
            }
            onSetStepValue={setStepValues}
            send={send}
            stateDef={stateDef}
            stateValue={stateValue}
          />
        )}
        {stateDef.meta.type === FsmDefMetaTypes.liveChat && (
          <LiveChatStep
            liveChatConfigId={stateDef.meta.liveChatConfigId}
            onFastAction={onFastAction}
            payload={JSON.parse(strTemplateToStr(stateDef.meta.payloadTransformer, context))}
          />
        )}
        {stateDef.meta.type === FsmDefMetaTypes.markdown && (
          <MarkdownStep text={strTemplateToStr(stateDef.meta.markdown, context)} />
        )}
      </div>
    );
  }
);
ChatSteps.displayName = "ChatSteps";
