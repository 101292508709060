import { defaultTheme } from "@do/ui-chat";
import { CacheProvider } from "@emotion/react";
import type { EmotionCache } from "@emotion/utils";
import { GlobalStyles } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { appWithTranslation } from "next-i18next";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useEffect } from "react";
import parser from "ua-parser-js";
import { trpc } from "../src/gateways/trpc";
import createEmotionCache from "../src/ui/createEmotionCache";
import { globalStyles } from "../src/ui/styles";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

type MyAppProps = AppProps & { emotionCache: EmotionCache };
const MyApp = ({ Component, emotionCache = clientSideEmotionCache, pageProps }: MyAppProps) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const userAgent = parser(navigator.userAgent);
      window.isAndroid = userAgent?.os?.name === "Android";
      window.isIos = userAgent?.os?.name === "iOS";
    }
  }, []);
  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <GlobalStyles styles={globalStyles} />
        <Component {...pageProps} />
        {process.env.NODE_ENV !== "production" && <ReactQueryDevtools />}
      </ThemeProvider>
    </CacheProvider>
  );
};

export default trpc.withTRPC(appWithTranslation(MyApp));
