import type { ChatMessage, ChatbotParams } from "@do/shared-types";
import { Box, useTheme } from "@mui/material";
import type { FC } from "react";
import { memo } from "react";
import { ChatContainerUi } from "../organisms/ChatContainerUi";
import { DEFAULT_MESSAGES } from "./fixtures";

export type PreviewChatUiProps = {
  autoScroll?: boolean;
  chatbotParams: ChatbotParams;
  messages?: ChatMessage[];
  onClose?: () => void;
  readonlyModeMessage?: string;
};

const noOp = () => {};

export const PreviewChatUi: FC<PreviewChatUiProps> = memo(
  ({ autoScroll, chatbotParams, messages, onClose, readonlyModeMessage }) => {
    // This theme is the one that calls this preview, not the do-chat one
    const callerTheme = useTheme();

    return (
      <Box
        borderRadius={4}
        overflow="hidden"
        width="100%"
        marginRight={0}
        marginLeft="auto"
        sx={{
          border: `1px solid ${callerTheme.palette.divider}`,
        }}
      >
        <ChatContainerUi
          autoScroll={autoScroll}
          chatbotParams={chatbotParams}
          disabled
          isPreviewMode={true}
          messages={messages || DEFAULT_MESSAGES}
          onClose={onClose}
          onFastAction={noOp}
          onSubmit={noOp}
          readonlyModeMessage={readonlyModeMessage}
        />
      </Box>
    );
  }
);
