import type { ChatMessage } from "@do/shared-types";
import { ChatCustomBlockTypes } from "@do/shared-types";

export const DEFAULT_MESSAGES: ChatMessage[] = [
  {
    id: "1",
    blocks: [
      {
        text: "Hello and welcome 🖐️\nI'm Anna your virtual assistant. You can exchange with me by clicking on the buttons or by asking me your question directly",
        type: ChatCustomBlockTypes.text,
      },
    ],
  },
  {
    id: "2",
    blocks: [
      {
        buttons: [
          { payload: "1", title: "👍 I'm already a client" },
          { payload: "2", title: "😒 I am not yet a customer" },
          { payload: "3", title: "📰 News from the energy market" },
        ],
        type: ChatCustomBlockTypes.buttons,
      },
    ],
  },
  {
    id: "3",
    blocks: [
      {
        text: "I would like to pay my bill",
        type: ChatCustomBlockTypes.text,
      },
    ],
    user: true,
  },
];
