import type { FC } from "react";
import type { MarkdownRendererProps } from "../../atoms";
import { MarkdownRenderer, StepContainer } from "../../atoms";

type MarkdownStepProps = MarkdownRendererProps;

export const MarkdownStep: FC<MarkdownStepProps> = ({ text }) => (
  <StepContainer bgcolor="background.paper">
    <MarkdownRenderer text={text} />
  </StepContainer>
);
