import type { CSSProperties, ReactElement } from "react";

export type IconAssetProps = {
  height?: number | string;
  width?: number | string;
  alt: string;
  dataURI?: string;
  style?: CSSProperties;
};

export const IconAsset = ({ height, width, alt, dataURI, style }: IconAssetProps): ReactElement => {
  return <img height={height} width={width} src={dataURI} alt={alt} style={style} />;
};
